




































































import { Component, Prop } from 'vue-property-decorator'

import { AddedToCartModalMixin } from '../../../../shared/mixins/addedToCartModal.mixin'
import { CartItem } from '../../../../checkout/molecules/CartItem'
import { Loader } from '../../../../shared/molecules/Loader'
import { ProductData } from '../../../../../contexts'
import { translateToCartItem } from '../../../../checkout/molecules/CartItem/CartItem.helpers'
import { CartItemProps } from '../../../molecules/CartItem/CartItem.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<Basic>({
  name: 'Basic',
  components: { SuggestedProductItem: CartItem, Loader }
})
export class Basic extends AddedToCartModalMixin {
  @Prop({ type: Array, required: true, default: [] })
  public suggestedProducts!: Array<ProductData>

  @Prop({ type: Boolean, required: true })
  public isLoading!: boolean

  public toCartItemProps (product: ProductData): CartItemProps {
    return translateToCartItem(product, ['noQuantity'])
  }

  public get slicedSuggestedProducts (): Array<ProductData> {
    return this.suggestedProducts.slice(0, 2)
  }
}

export default Basic
